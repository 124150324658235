*{
	margin:0;
	padding: 0;
	box-sizing: border-box;
}

:root{
	--primary-color: #0067ab;
	--background-color: #f7fbfd;
	--card-shadow: #004a8c24 0px 2px 6px;
}

body{
	background: var(--background-color);
	font-family: 'Roboto';
}
